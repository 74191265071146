<template>
    <main>
        <ButtonGroup shape="circle">
            <Button icon="md-refresh" @click="loadData">刷新</Button>
            <Button icon="md-add-circle" @click="create()" v-if="Auth([ 'AuthRole', 'create' ])">添加角色</Button>
        </ButtonGroup>

        <Divider />
        <Table border :columns="columns" :data="data"></Table>
    </main>
</template>

<script>
    export default {
        data()
        {
            return {
                sort   : '',
                data   : [],
                columns: [
                    {
                        title   : '名称',
                        key     : 'title',
                        minWidth: 150
                    },
                    {
                        title   : '权重',
                        minWidth: 100,
                        key     : 'weight',
                        render  : (h, params) => {
                            var html = [ h('span', params.row.weight) ]

                            if (params.row.stick)
                            {
                                html.push(h('Icon', {
                                    props: {
                                        type: 'md-arrow-round-up'
                                    },
                                    class: 'text-blue ml-xs'
                                }))
                            }

                            return h('div', html)
                        }
                    },
                    {
                        title   : '状态',
                        minWidth: 90,
                        key     : 'status',
                        render  : (h, params) => {
                            return h('Icon', {
                                props: {
                                    type: params.row.status ? 'ios-checkmark-circle' : 'ios-remove-circle'
                                },
                                class: params.row.status ? 'text-green' : 'text-red'
                            })
                        }
                    },
                    {
                        title   : '操作',
                        minWidth: 110,
                        align   : 'center',
                        render: (h, params) => {
                            var html = []

                            if (this.Auth([ 'AuthRole', 'update' ]))
                            {
                                html.push(h('Button', {
                                    props: {
                                        type: 'success',
                                        size: 'small',
                                        icon: 'md-create'
                                    },
                                    class: 'mg-lr-xs',
                                    on: {
                                        click: () => {
                                            this.update(params.row)
                                        }
                                    }
                                }))
                            }

                            if (this.Auth([ 'AuthRole', 'delete' ]))
                            {
                                html.push(h('Button', {
                                    props: {
                                        type: 'error',
                                        size: 'small',
                                        icon: 'md-trash'
                                    },
                                    class: 'mg-lr-xs',
                                    on: {
                                        click: () => {
                                            this.remove(params.row)
                                        }
                                    }
                                }))
                            }

                            return h('div', html)
                        }
                    }
                ]
            }
        },
        mounted()
        {
            this.loadData()
        },
        methods: {
            loadData()
            {
                this.$http.get('/authRole/list')
                    .then(res => {
                        this.data  = res
                    })
            },
            create()
            {
                this.$router.push('/auth-role/create')
            },
            update(item)
            {
                this.$router.push('/auth-role/update/' + item.id)
            },
            remove(item)
            {
                this.$Modal.confirm({
                    title  : '确定要删除吗？',
                    content: item.title,
                    onOk   : () => {
                        this.$http.post('/authRole/delete/id/' + item.id)
                            .then(res => {
                                this.loadData()
                            })
                    }
                })
            }
        }
    }
</script>